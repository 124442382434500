<template>
  <div class="redEnvelopes">
    <Header isNoBg>我的钥匙</Header>
    <div
      style="
        background: linear-gradient(#3beaed, #7cd2ff);
        padding-bottom: 20px;
      "
    >
      <div class="list">
        <div class="left">
          <div class="money">87</div>
          <div class="title">我的钥匙(个)</div>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="product">
        <div
          class="tabItem"
          :class="item.id === tab.active ? 'active' : null"
          v-for="item in tab.list"
          :key="item.id"
          @click="tab.active = item.id"
        >
          <span>{{ item.name }}</span>
        </div>
      </div>
      <div class="record">
        <div class="recordItem" v-for="item in getRecordList" :key="item.id">
          <div class="left">
            <div class="title">{{ item.name }}</div>
            <div class="time">{{ item.time }}</div>
          </div>
          <div class="right">{{ item.tab }}</div>
        </div>
      </div>
      <div style="padding-bottom: 60px; overflow: auto">
        <div class="even">
          查看更多
          <img src="../../../../static/image/bottom.png" alt="" />
        </div>
      </div>
    </div>

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="showPicker = false"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      columns: ['转入', '转出'],
      tab: {
        list: [
          { id: 0, name: '获得记录' },
          { id: 1, name: '消耗记录' },
        ],
        active: 0,
      },
      showPicker: false,
      recordList: [
        {
          id: 0,
          name: '转出到余额',
          time: '2022-03-06 19:02:03',
          money: '-100元',
        },
        {
          id: 1,
          name: '签到领红包',
          time: '2022-03-06 19:02:03',
          money: '-100元',
        },
        {
          id: 2,
          name: '转出到余额',
          time: '2022-03-06 19:02:03',
          money: '-100元',
        },
      ],
      getRecordList: [
        {
          id: 0,
          name: '获得5个钥匙',
          tab: '购买',
          time: '2022-03-06 19:02:03',
        },
        {
          id: 1,
          name: '获得5个钥匙',
          tab: '签到',
          time: '2022-03-06 19:02:03',
        },
        {
          id: 2,
          name: '获得5个钥匙',
          tab: '奖励',
          time: '2022-03-06 19:02:03',
        },
      ],
      consumeRecordList: [
        {
          id: 0,
          name: '获得5个钥匙',
          tab: '合成',
          time: '2022-03-06 19:02:03',
        },
        {
          id: 1,
          name: '获得5个钥匙',
          tab: '合成',
          time: '2022-03-06 19:02:03',
        },
        {
          id: 2,
          name: '获得5个钥匙',
          tab: '合成',
          time: '2022-03-06 19:02:03',
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.redEnvelopes {
  background: linear-gradient(#3beaed, #7cd2ff);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  .list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
    .left {
      text-align: center;
      flex: 1;
      .money {
        font-size: 25px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 32px;
      }
      .title {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 32px;
      }
    }
    .right {
      text-align: center;
      flex: 1;
      .money {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 32px;
      }
      .title {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 32px;
      }
    }
  }
}
.block {
  width: 100%;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px 15px;
  z-index: 9;
  flex: 1;
  box-sizing: border-box;
  margin-top: -20px;
  .record {
    margin-top: 25px;
    .recordItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .left {
        .title {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-bottom: 3px;
        }
        .time {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #cecece;
        }
      }
      .right {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .product {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tabItem {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      font-weight: 400;
      color: #999;
      &.active {
        color: #000000;
        font-weight: 500;
        span {
          display: inline-block;
          position: relative;
          &::before {
            display: block;
            content: '';
            width: 100%;
            height: 3px;
            background: #70d6fc;
            position: absolute;
            bottom: 3px;
            left: 0;
            z-index: -1;
          }
        }
      }
    }
  }
  .product_list {
    display: flex;
    font-size: 16px;
    line-height: 60px;
    justify-content: space-between;
    margin-right: 20px;
    .product_text {
      display: flex;
      align-items: center;
    }
    .product_num {
      margin-left: 10px;
      display: flex;
      font-size: 12px;
    }
    .num_several {
      margin-left: 5px;
      font-size: 10px;
      color: #aaa;
      &.active {
        color: #fab878;
        span {
          padding: 3px;
        }
      }
      &.unActive {
        color: #cecece;
        span {
          background: #f8f8f8;
          padding: 3px;
        }
      }
      &.lock {
        color: #009944;
        span {
          background: #deffee;
          padding: 3px;
        }
      }
    }
    .product_img {
      width: 4px;
      height: 6px;
      margin-left: 5px;
    }
  }
  .even {
    font-size: 12px;
    color: #abaab9;
    background-color: #abaab957;
    width: 70px;
    text-align: center;
    border-radius: 10px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding-left: 15px;
    img {
      width: 6px;
      height: 4px;
    }
  }
}
</style>
